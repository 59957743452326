import UserImage from '../../assets/img/user1.png';
import UserImage2 from '../../assets/img/user2.png';
import UserImage3 from '../../assets/img/user3.png';
import UserImage4 from '../../assets/img/user4.png';
import UserImage5 from '../../assets/img/user5.png';
import UserImage6 from '../../assets/img/user6.png';
import UserImage7 from '../../assets/img/user7.png';
import SERVICES from './serviceDummyData';

import User7Landing from '../../assets/img/wanna/landing1.png';

const jubayer = {
	id: 1,
	username: 'jubayer',
	name: 'Jubayer Ahmed',
	surname: 'JA',
	position: 'Manager',
	src: UserImage,
	srcSet: UserImage,
	isOnline: true,
	isReply: true,
	color: 'primary',
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
};

const sabbir = {
	id: 2,
	username: 'sabbir',
	name: 'Sabbir Ahmed',
	surname: 'SA',
	position: 'Staff',
	src: UserImage2,
	srcSet: UserImage2,
	isOnline: true,
	color: 'warning',
	services: [SERVICES.SNOWBOARDING, SERVICES.ICE_SKATING, SERVICES.KITE_SURFING],
};

const saika = {
	id: 3,
	username: 'saika',
	name: 'Saika Alam',
	surname: 'SA',
	position: 'Staff',
	src: UserImage3,
	srcSet: UserImage3,
	isOnline: true,
	color: 'secondary',
	services: [SERVICES.YOGA, SERVICES.HANDBALL, SERVICES.CRICKET],
};

const sohel = {
	id: 4,
	username: 'sohel',
	name: 'Sohel Chowdhury',
	surname: 'SC',
	position: 'Worker',
	src: UserImage4,
	srcSet: UserImage4,
	isOnline: false,
	color: 'info',
	services: [SERVICES.HIKING, SERVICES.FOOTBALL, SERVICES.HANDBALL],
};

const rana = {
	id: 5,
	username: 'rana',
	name: 'Rana Ahmed',
	surname: 'RA',
	position: 'Worker',
	src: UserImage5,
	srcSet: UserImage5,
	isOnline: false,
	color: 'success',
	services: [SERVICES.ICE_SKATING, SERVICES.TENNIS, SERVICES.SNOWBOARDING, SERVICES.YOGA],
};

const tawhid = {
	id: 6,
	username: 'tawhid',
	name: 'Tawhid Ahmed',
	surname: 'TA',
	position: 'Staff',
	src: UserImage6,
	srcSet: UserImage6,
	isOnline: true,
	color: 'warning',
	services: [SERVICES.VOLLEYBALL, SERVICES.CRICKET],
};

const kamal = {
	id: 7,
	username: 'kamal',
	name: 'Kamal Khan',
	surname: 'KK',
	position: 'Worker',
	src: UserImage7,
	srcSet: UserImage7,
	isOnline: false,
	color: 'danger',
	fullImage: User7Landing,
};

const USERS = {
	JUBAYER: jubayer,
	SABBIR: sabbir,
	SAIKA: saika,
	SOHEL: sohel,
	RANA: rana,
	KAMAL: kamal,
	TAWHID: tawhid,
};

export function getUserDataWithUsername(username) {
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id) {
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export default USERS;
