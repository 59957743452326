import React from 'react';
import {
	chemistMenuGroupManagement,
	primaryChemistMenu,
	chemistMenuGroupLogistics,
	chemistMenuGroupOverview,
} from '../menu';
import ChemistHeader from '../pages/common/Headers/ChemistHeader';

const headers = [
	{
		path: chemistMenuGroupOverview.reports.subMenu.target_and_achievement.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reports.subMenu.depot_Revenue_collection.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reports.subMenu.sales.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reports.subMenu.prescriptions.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{ path: primaryChemistMenu.product.path, element: <ChemistHeader />, exact: true },
	{ path: primaryChemistMenu.warehouses.path, element: <ChemistHeader />, exact: true },
	{ path: primaryChemistMenu.pharmacies.path, element: <ChemistHeader />, exact: true },
	{ path: chemistMenuGroupManagement.leads.path, element: <ChemistHeader />, exact: true },
	{ path: chemistMenuGroupManagement.leads.path, element: <ChemistHeader />, exact: true },
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.stock.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.transfers.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.minimum_quantities.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupLogistics.requisitions.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupManagement.announcements.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.users.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.doctors.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupLogistics.deliveries.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupManagement.targets.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.dashboard.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: `*`,
		element: <ChemistHeader />,
	},
	{ path: chemistMenuGroupManagement.leads.path, element: <ChemistHeader />, exact: true },
];

export default headers;
