export const primaryChemistMenu = {
	settings: {
		id: 'settings',
		text: 'Settings',
	},
	product: {
		id: 'product',
		text: 'Products',
		path: '/products',
		icon: 'CardGiftcard',
		subMenu: null,
	},
	doctors: {
		id: 'doctor',
		text: 'Doctors',
		path: '/doctors',
		icon: 'GroupAdd',
	},
	users: {
		id: 'userManagement',
		text: 'Users',
		path: '/users',
		icon: 'Group',
	},
	warehouses: {
		id: 'warehouses',
		text: 'Medicine Depots',
		path: '/warehouses',
		icon: 'MapsHomeWork',
	},
	pharmacies: {
		id: 'pharmacies',
		text: 'Pharmacies',
		path: '/pharmacies',
		icon: 'Medication',
	},
};

export const chemistMenuGroupOverview = {
	chemistMenuGroupOverview: {
		id: 'Overview',
		text: 'Overview',
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	survey: {
		id: 'survey',
		text: 'Performance Survey',
		path: '/survey',
		icon: 'InsertChart',
		subMenu: null,
	},
	coverage: {
		id: 'coverage',
		text: 'Market Coverage',
		path: '/coverage',
		icon: 'LocalConvenienceStore',
		subMenu: null,
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: '/reports',
		icon: 'Summarize',
		subMenu: {
			target_and_achievement: {
				id: 'target_and_achievement',
				text: 'Target & Achievement',
				path: 'reports/target-and-achievement',
				icon: 'TrackChanges',
			},
			depot_Revenue_collection: {
				id: 'depot_Revenue_collection',
				text: 'Depot Revenue Collection',
				path: 'reports/depot-revenue-collection',
				icon: 'CollectionsBookmark',
			},
			sales: {
				id: 'sales',
				text: 'Sales',
				path: 'reports/sales',
				icon: 'Loyalty',
			},
			prescriptions: {
				id: 'prescriptions',
				text: 'Prescriptions',
				path: 'reports/prescriptions',
				icon: 'Article',
			},
		},
	},
};

export const chemistMenuGroupManagement = {
	chemistMenuGroupManagement: {
		id: 'chemistMenuGroupManagement',
		text: 'Management',
	},
	announcements: {
		id: 'announcement',
		text: 'Annoucements',
		path: '/announcements',
		icon: 'Campaign',
		subMenu: null,
	},
	leads: {
		id: 'leads',
		text: 'Leads',
		path: '/leads',
		icon: 'Leaderboard',
		subMenu: null,
	},
	targets: {
		id: 'targets',
		text: 'Targets',
		path: '/targets',
		icon: 'Adjust',
		subMenu: null,
	},
};

export const chemistMenuGroupLogistics = {
	chemistMenuGroupLogistics: {
		id: 'chemistMenuGroupLogistics',
		text: 'Logistics',
	},
	deliveries: {
		id: 'deliveries',
		text: 'Deliveries',
		path: '/deliveries',
		icon: 'LocalShipping',
		subMenu: null,
	},
	requisitions: {
		id: 'requisitions',
		text: 'Requisitions',
		path: '/requisitions',
		icon: 'Assignment',
		subMenu: null,
	},
	inventory: {
		id: 'inventory',
		text: 'Inventory',
		path: '/inventory',
		icon: 'Inventory2',
		subMenu: {
			stock: {
				id: 'stock',
				text: 'Stock',
				path: 'inventory/stock',
				icon: 'Inventory',
			},
			transfers: {
				id: 'transfers',
				text: 'Transfers',
				path: 'inventory/transfers',
				icon: 'TransferWithinAStation',
			},
			minimum_quantities: {
				id: 'minimum_quantities',
				text: 'Minimum Quantities',
				path: 'inventory/minimum-quantities',
				icon: 'ProductionQuantityLimits',
			},
		},
	},
};

export const layoutPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	headerAndSubheader: {
		id: 'headerAndSubheader',
		text: 'Header & Subheader',
		path: 'page-layouts/header-and-subheader',
		icon: 'ViewAgenda',
	},
	onlyHeader: {
		id: 'onlyHeader',
		text: 'Only Header',
		path: 'page-layouts/only-header',
		icon: 'ViewStream',
	},
	onlySubheader: {
		id: 'onlySubheader',
		text: 'Only Subheader',
		path: 'page-layouts/only-subheader',
		icon: 'ViewStream',
	},
	onlyContent: {
		id: 'onlyContent',
		text: 'Only Content',
		path: 'page-layouts/only-content',
		icon: 'WebAsset',
	},
};

export default primaryChemistMenu;
