import React, { lazy } from 'react';
import {
	primaryChemistMenu,
	layoutPages,
	chemistMenuGroupOverview,
	chemistMenuGroupManagement,
	chemistMenuGroupLogistics,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const CHEMIST_CONTENT_ROUTES = {
	DASHBOARD: {
		DASHBOARD: lazy(() => import('../pages/chemist-pages/dashboard/DashboardView')),
	},
	USER_MANAGEMENT: {
		USERS: lazy(() => import('../pages/chemist-pages/usersmgmt/UserManagement')),
	},
	PHARMACYS: {
		PHARMACY_LIST: lazy(() => import('../pages/chemist-pages/pharmacy/PharmacyList')),
	},
	PRODUCTS: {
		PRODUCTS_LIST: lazy(() => import('../pages/chemist-pages/product/ProductsList')),
	},
	DOCTORS: {
		DOCTORS_LIST: lazy(() => import('../pages/chemist-pages/doctor/DoctorsManagement')),
	},
	WAREHOUSE: {
		WAREHOUSE_LIST: lazy(() => import('../pages/chemist-pages/warehouse/WareHouseList')),
	},
	ANNOUNCEMENT: {
		ANNOUNCEMENT_LIST: lazy(() =>
			import('../pages/chemist-pages/announcement/AnnouncementList'),
		),
	},
	LEADS: {
		LEADS_LIST: lazy(() => import('../pages/chemist-pages/leads/LeadsList')),
	},
	STOCK: {
		STOCK_LIST: lazy(() => import('../pages/chemist-pages/inventory/Stock')),
	},
	TRANSFERS: {
		TRANSFERS_LIST: lazy(() => import('../pages/chemist-pages/inventory/Transfers')),
	},
	QUANTITIES: {
		QUANTITIES_LIST: lazy(() => import('../pages/chemist-pages/inventory/Quantities')),
	},
	REQUISTIONS: {
		REQUISTIONS_LIST: lazy(() => import('../pages/chemist-pages/requisitions/Requisitions')),
	},
	TARGETANDACHIEVEMENT: {
		TARGETANDACHIEVEMENT_LIST: lazy(() =>
			import('../pages/chemist-pages/reports/TargetAndAchievement'),
		),
	},
	DEPOTREVENUECOLLECTION: {
		DEPOTREVENUECOLLECTION_LIST: lazy(() =>
			import('../pages/chemist-pages/reports/DepotRevenueCollection'),
		),
	},
	SALESREPORT: {
		SALESREPORT_LIST: lazy(() => import('../pages/chemist-pages/reports/SalesReport')),
	},
	PRESCRIPTIONREPORT: {
		PRESCRIPTIONREPORT_LIST: lazy(() =>
			import('../pages/chemist-pages/reports/PrescriptionReport'),
		),
	},
	DELIVERIES: {
		DELIVERIES_LIST: lazy(() => import('../pages/chemist-pages/deliveries/Deliveries')),
	},
	MARKETCOVERAGE: {
		MARKET_COVERAGE: lazy(() =>
			import('../pages/chemist-pages/market-coverage/MarketCoverageView'),
		),
	},
	TARGETS: {
		TARGETSVIEW: lazy(() => import('../pages/chemist-pages/targets/TargetView')),
	},
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Chemist Pages
	 */
	/**
	 * Dashboard
	 */
	{
		path: chemistMenuGroupOverview.dashboard.path,
		element: <CHEMIST_CONTENT_ROUTES.DASHBOARD.DASHBOARD />,
		exact: true,
	},

	/**
	 * Users
	 */
	{
		path: primaryChemistMenu.pharmacies.path,
		element: <CHEMIST_CONTENT_ROUTES.PHARMACYS.PHARMACY_LIST />,
		exact: true,
	},
	/**
	 * WAREHOUSE
	 */ {
		path: primaryChemistMenu.warehouses.path,
		element: <CHEMIST_CONTENT_ROUTES.WAREHOUSE.WAREHOUSE_LIST />,
		exact: true,
	},

	/**
	 * Users
	 */
	{
		path: primaryChemistMenu.users.path,
		element: <CHEMIST_CONTENT_ROUTES.USER_MANAGEMENT.USERS />,
		exact: true,
	},
	/**
	 * Products
	 */
	{
		index: true,
		path: primaryChemistMenu.product.path,
		element: <CHEMIST_CONTENT_ROUTES.PRODUCTS.PRODUCTS_LIST />,
		exact: true,
	},
	/**
	 * Doctors
	 */
	{
		index: true,
		path: primaryChemistMenu.doctors.path,
		element: <CHEMIST_CONTENT_ROUTES.DOCTORS.DOCTORS_LIST />,
		exact: true,
	},

	/*
	 * ANNOUNCEMENT
	 */
	{
		path: chemistMenuGroupManagement.announcements.path,
		element: <CHEMIST_CONTENT_ROUTES.ANNOUNCEMENT.ANNOUNCEMENT_LIST />,
		exact: true,
	},
	/*
	 * Leads
	 */
	{
		path: chemistMenuGroupManagement.leads.path,
		element: <CHEMIST_CONTENT_ROUTES.LEADS.LEADS_LIST />,
		exact: true,
	},
	/*
	 * Stock
	 */
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.stock.path,
		element: <CHEMIST_CONTENT_ROUTES.STOCK.STOCK_LIST />,
		exact: true,
	},
	/*
	 * TRANSFERS
	 */
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.transfers.path,
		element: <CHEMIST_CONTENT_ROUTES.TRANSFERS.TRANSFERS_LIST />,
		exact: true,
	},
	/*
	 * quantities
	 */
	{
		path: chemistMenuGroupLogistics.inventory.subMenu.minimum_quantities.path,
		element: <CHEMIST_CONTENT_ROUTES.QUANTITIES.QUANTITIES_LIST />,
		exact: true,
	},
	/*
	 * REQUISTIONS
	 */
	{
		path: chemistMenuGroupLogistics.requisitions.path,
		element: <CHEMIST_CONTENT_ROUTES.REQUISTIONS.REQUISTIONS_LIST />,
		exact: true,
	},
	/*
	 * TargetAndAchievement
	 */
	{
		path: chemistMenuGroupOverview.reports.subMenu.target_and_achievement.path,
		element: <CHEMIST_CONTENT_ROUTES.TARGETANDACHIEVEMENT.TARGETANDACHIEVEMENT_LIST />,
		exact: true,
	},
	/*
	 * DEPOTREVENUECOLLECTION
	 */
	{
		path: chemistMenuGroupOverview.reports.subMenu.depot_Revenue_collection.path,
		element: <CHEMIST_CONTENT_ROUTES.DEPOTREVENUECOLLECTION.DEPOTREVENUECOLLECTION_LIST />,
		exact: true,
	},
	/*
	 * SALESREPORT
	 */
	{
		path: chemistMenuGroupOverview.reports.subMenu.prescriptions.path,
		element: <CHEMIST_CONTENT_ROUTES.PRESCRIPTIONREPORT.PRESCRIPTIONREPORT_LIST />,
		exact: true,
	},
	/*
	 * SALESREPORT
	 */
	{
		path: chemistMenuGroupOverview.reports.subMenu.sales.path,
		element: <CHEMIST_CONTENT_ROUTES.SALESREPORT.SALESREPORT_LIST />,
		exact: true,
	},
	/*
	 * DELIVERIES
	 */
	{
		path: chemistMenuGroupLogistics.deliveries.path,
		element: <CHEMIST_CONTENT_ROUTES.DELIVERIES.DELIVERIES_LIST />,
		exact: true,
	},
	/*
	 * MARKETCOVERAGE
	 */
	{
		path: chemistMenuGroupOverview.coverage.path,
		element: <CHEMIST_CONTENT_ROUTES.MARKETCOVERAGE.MARKET_COVERAGE />,
		exact: true,
	},
	/*
	 * TARGETS
	 */
	{
		path: chemistMenuGroupManagement.targets.path,
		element: <CHEMIST_CONTENT_ROUTES.TARGETS.TARGETSVIEW />,
		exact: true,
	},
	/**
	 * Auth Page
	 */
	{
		path: layoutPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: layoutPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: layoutPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;
